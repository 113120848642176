import React from "react";
import "./PortfolioApp.css"
import Homepage from "./pages/Homepage";
import "./components/About Me/Bio.css"

function App() {
    
    return (

            <Homepage />
        
        );
  }
export default App;


